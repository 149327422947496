import React, { Fragment } from 'react';
import Head from 'components/LV1/Seo';
import Service from 'components/LV3/Service';

const ServicePage: React.FC = () => (
  <Fragment>
    <Head
      title="SERVICE"
      description="コクラボ株式会社のサービス紹介ページ。さまざまな種類のWebサイトやWebサービスを企画・開発しています。クリエイティブ・マーケティング・広告・データ分析などの領域も対応可能です。"
    />
    <Service />
  </Fragment>
);
export default ServicePage;