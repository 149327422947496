import React, { useState } from 'react';
import styled from 'styled-components';
import Aos from 'components/LV1/Aos';
import SlideText from 'components/LV1/AosSlideText';
import Swiper from 'react-id-swiper';
// import "swiper/swiper.min.css";
// import "swiper/components/navigation/navigation.min.css";
import SwiperCore, { Navigation } from 'swiper';
SwiperCore.use([Navigation]);

const Wrap = styled.div`
  padding: 80px 0 0;
  ${props => props.theme.media.tab} {
    padding: 45px 0 0;
  }
`;

const Inner = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: auto;
`;

const Header = styled.div`
  display: flex;
  margin-bottom: 76px;
  ${props => props.theme.media.tab} {
    display: block;
    margin-bottom: 40px;
  }
`;

const SectionTitle = styled.div`
  white-space: nowrap;
  p {
    &.main {
      font-size: 2.6rem;
      letter-spacing: 0.075em;
      font-family: 'Marcellus', serif;
    }
    &.sub {
      font-size: 1.4rem;
      letter-spacing: 0.2em;
      margin-top: 25px;
    }
  }
  ${props => props.theme.media.tab} {
    white-space: unset;
    p {
      &.main {
        border-bottom: unset;
      }
      &.sub {
        margin-top: 15px;
      }
    }
  }
`;

const Description = styled.div`
  position: relative;
  font-size: 1.6rem;
  letter-spacing: 0.18em;
  margin-left: 70px;
  padding-left: 70px;
  border-left: 1px solid #000;
  ${props => props.theme.media.tab} {
    margin-left: 0;
    padding-left: 0;
    margin-top: 30px;
    padding-top: 30px;
    border-left: unset;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 80px;
      height: 1px;
      background-color: #000;
    }
  }
`;

const CardWrap = styled.ul<{ isView: boolean }>`
  .swiper-container {
    display: none;
    ${props => props.isView && 'display: block;'}
    overflow: visible;
    .swiper-wrapper {
      .swiper-slide {
        height: auto;
      }
    }
    .swiper-button-next:after,
    .swiper-button-prev:after {
      content: '→';
      font-size: 16px;
      font-family: unset;
      color: #000;
    }
    .swiper-button-prev:after {
      content: '←';
    }
    .swiper-button-next {
      bottom: -40px;
      top: unset;
      left: unset;
      right: 10px;
      width: 30px;
      height: 30px;
      &:before {
        content: '/';
        position: absolute;
        left: -10px;
        font-size: 1.7rem;
        color: #a5a5a5;
      }
    }
    .swiper-button-prev {
      top: unset;
      bottom: -40px;
      left: unset;
      right: 55px;
      width: 30px;
      height: 30px;
    }
  }
`;

const Card = styled.li`
  div {
    height: 95%;
    margin: 10px 0;
    padding: 60px;
    box-shadow: 2px 2px 10px 5px #dbdbdb;
    background-color: #fff;
    p {
      font-size: 1.6rem;
      letter-spacing: 0.2em;
      line-height: 1.8;
      &.card-title {
        font-size: 2rem;
        margin: 0 auto 50px;
        text-align: center;
      }
      a {
        text-decoration: underline;
      }
    }
    ${props => props.theme.media.tab} {
      padding: 30px 25px;
      p {
        font-size: 1.5rem;
        &.card-title {
          margin: 0 auto 30px;
        }
      }
    }
  }
`;

type Props = {
  title: string;
  titleSub: string;
  description: string;
  list: {
    title: string;
    text: any;
  }[];
};

const Service: React.FC<Props> = (props: Props) => {
  const [isViewSwiper, setIsViewSwiper] = useState(false);
  const params = {
    clickable: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    spaceBetween: 15,
    slidesPerView: 1.1,
    breakpoints: {
      768: {
        spaceBetween: 30,
        slidesPerView: 2.25,
      },
    },
  }

  setTimeout(() => {
    setIsViewSwiper(true);
  }, 100);

  return (
    <Wrap>
      <Inner>
        <Header>
          <SectionTitle>
            <p className="main">
              <SlideText>
                {props.title}
              </SlideText>
            </p>
            <p className="sub">
              <SlideText delay="100" duration="1400">
                {props.titleSub}
              </SlideText>
            </p>
          </SectionTitle>
          <Description>
            <SlideText delay="300" duration="1400">
              {props.description}
            </SlideText>
          </Description>
        </Header>
        <Aos>
          <CardWrap isView={isViewSwiper}>
            <Swiper {...params}>
              {props.list.map((item, index) => (
                <Card key={index}>
                  <div>
                    <p className="card-title">{item.title}</p>
                    <p>{item.text}</p>
                  </div>
                </Card>
              ))}
            </Swiper>
          </CardWrap>
        </Aos>
      </Inner>
    </Wrap>
  );
};
export default Service;
