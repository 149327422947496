import React, { Fragment } from 'react';
import styled from 'styled-components';
import { ServicesListData } from 'components/variables/services';
import StaticTemplate from 'components/Templates/Static';
import SlideText from 'components/LV1/AosSlideText';
import ServiceInfoSet from 'components/LV2/ServiceInfoSet';

const HeadText = styled.div`
  position: relative;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto 54px;
  padding: 0 15px;
  line-height: 1.75;
  z-index: 1;
  a {
    text-decoration: underline;
  }
  ${props => props.theme.media.tab} {
    margin-bottom: 48px;
  }
`;

const Service: React.FC = () => {
  return (
    <StaticTemplate
      title="Our Service"
      titleSub="サービス"
      isNoMaxWidth
    >
      <HeadText>
        <SlideText>
          ITに関係するさまざまなサービスを提供しています。
          <br />
          コンサルティングを含む要件定義から運用保守まで、開発全行程を多様な技術を利用し一気通貫で対応することが可能です。
          <br />
          開発案件のほか、ITを有効活用するためのコンサルティングや技術的なサポートなども行っています。
          <br />
          <br />
          ※対応可能技術の詳細は<a href="https://coc-labo.notion.site/8493154a7feb471abfe3c6b03f31cfa9" target="_blank" rel="noopener noreferrer">こちら</a>
        </SlideText>
      </HeadText>
      <ServiceInfoSet
        isBgWhite
        num={ServicesListData[0].no}
        title={ServicesListData[0].title}
        titleSub={ServicesListData[0].titleSub}
        description={ServicesListData[0].text}
        imgPath="service-1.jpg"
        imgAlt="service-1"
        list={[
          {
            title: 'ROCKET LAUNCH',
            text: '豊富な開発の知見を活かし、限られたリソースの中で高品質なサービスを高速に開発&ローンチすることを得意としています。',
          },
          {
            title: 'GROWTH',
            text: '作って終わりではなく、ローンチ後のメンテナンスやサービスグロースに関する施策立案および実施なども対応可能です。',
          },
          {
            title: '少数精鋭チーム',
            text: '経験豊富なメンバーが対応するため、さまざまなプラットフォームや開発手法や設計手法に対応することが可能です。また、技術スタックが広いため、柔軟で精度の高い意思決定を行うことが可能です。',
          },
          {
            title: '対応可能技術',
            text:
              <Fragment>
                プロダクトの内容や状況に踏まえたうえで、最適な技術を選定して利用することが可能です。
                <br />
                <br />
                ※対応可能技術の詳細は<a href="https://coc-labo.notion.site/8493154a7feb471abfe3c6b03f31cfa9" target="_blank" rel="noopener noreferrer">こちら</a>
              </Fragment>,
          },
        ]}
      />
      <ServiceInfoSet
        num={ServicesListData[1].no}
        title={ServicesListData[1].title}
        titleSub={ServicesListData[1].titleSub}
        description={ServicesListData[1].text}
        imgPath="service-2.jpg"
        imgAlt="service-2"
        list={[
          {
            title: 'クリエイティビティ',
            text: '感情に訴求し、かつ伝えたいことがより伝わるような表現を大切にしています。',
          },
          {
            title: '細部へのこだわり',
            text: 'Webサイト訪問者にとって使いやすくなるよう、UX/UIにもこだわっています。Webサイトの構成や表示スピードなど、ぱっと気が付きにくい細かな箇所も考慮して対応します。',
          },
          {
            title: '運営サポート',
            text: 'Webサイト公開後のサーバ・ドメイン管理、コンテンツ更新や各種メンテナンスなど、運営全般をまるっとサポートします。',
          },
        ]}
      />
      <ServiceInfoSet
        isBgWhite
        num={ServicesListData[2].no}
        title={ServicesListData[2].title}
        titleSub={ServicesListData[2].titleSub}
        description={ServicesListData[2].text}
        imgPath="service-3.jpg"
        imgAlt="service-3"
        list={[
          { title: '戦略策定', text: 'ヒアリング、ディスカッション、分析、競合調査などを通じて、データに基づく課題整理および戦略策定を行ないます。' },
          { title: 'データ分析', text: 'Webサイトを継続的に改善していくために、さまざまなデータを利用し、多角的にデータ分析を行います。' },
          { title: 'SEO/MEO対策', text: '豊富な知見をもとにコンテンツの企画・設計・運用を行い、効果的なSEOおよびMEO対策を行います。検索エンジンの上位表示のためだけの対策ではなく、Webサイトの目的や戦略に沿って最適な施策を策定および実施し、継続的な改善を行います。' },
          { title: '広告運用', text: '市場動向や業界・商材をふまえた最適な広告運用を提供します。戦略策定から事後分析およびレポーティングまで幅広く対応可能です。' },
        ]}
      />
      <ServiceInfoSet
        num={ServicesListData[3].no}
        title={ServicesListData[3].title}
        titleSub={ServicesListData[3].titleSub}
        description={ServicesListData[3].text}
        imgPath="service-4.jpg"
        imgAlt="service-4"
        list={[
          { title: 'IT戦略/構想立案支援', text: '事業戦略に基づき、IT戦略、IT投資、IT組織、IT人材に関するロードマップを策定するなど、中長期計画の立案を支援します。具体的には、ITにおいて積極的な投資をする領域と低コストを目指す領域を区別し、企業の競争優位の確立と事業成功への関与性、リスク診断やIT成熟度とのギャップを考慮したIT戦略を中長期な視点で立案し、費用対効果の高いIT投資や一貫性のある戦略実行の意思決定を支援します。' },
          { title: '業務改善/システム導入支援', text: '中長期計画・経営戦略・事業戦略に基づき、現状業務の本質的な問題を分析し、改革・改善方策を具体化します。 また、標準化/平準化/自動化などの業務効率化・最適化のための施策やビジネスルールの見直し等を行うことにより、あるべき業務の実現を支援します。 システムの経営貢献度合い、システム利用者ニーズとのFit&Gap分析などによる診断を行い、 業務で利用されるシステムのあるべきアーキテクチャを規定し、システム導入計画の策定を支援します。' },
          {
            title: 'プロジェクトマネジメント支援',
            text:
              <Fragment>
                サービス開発やシステム開発などの実プロジェクトに参画し、プロジェクト管理や技術的な支援を行います。費用対効果を熟考し、パフォーマンスが高いIT投資が実現できるよう支援します。
                <br />
                ・プロジェクト計画を立案
                <br />
                ・プロジェクト管理に関する基準の策定
                <br />
                ・開発に関する標準の策定
                <br />
                ・プロジェクト管理および運用に関するサポート
              </Fragment>,
          },
        ]}
      />
      <ServiceInfoSet
        isBgWhite
        num={ServicesListData[4].no}
        title={ServicesListData[4].title}
        titleSub={ServicesListData[4].titleSub}
        description={ServicesListData[4].text}
        imgPath="service-5.jpg"
        imgAlt="service-5"
        list={[
          {
            title: 'アプリケーション保守',
            text:
              <Fragment>
                ・機能の追加・変更・削除
                <br />
                ・システムの不具合改修
                <br />
                ・システムの解析(ソースなど)
                <br />
                ・ドキュメントの作成(設計書・マニュアルなど)
                <br />
                ・OS、ミドルウェアのバージョンアップ作業
                <br />
                ・試験環境の構築・運用
              </Fragment>,
          },
          {
            title: 'サーバ保守',
            text:
              <Fragment>
                ・トラブル発生時の一次切り分け
                <br />
                ・各メーカへの問い合わせ代行
                <br />
                ・障害復旧作業
                <br />
                ・システムの解析(ソース、各種設定など)
                <br />
                ・ドキュメントの作成(設定シート・マニュアルなど)
                <br />
                ・OS、ミドルウェアのバージョンアップ作業
                <br />
                ・サーバ移行作業（仮想化、クラウド化など）
              </Fragment>,
          },
          {
            title: 'サーバ運用',
            text:
              <Fragment>
                ・対象サーバ及びサービスに関するお問い合わせ窓口の設置
                <br />
                ・定期点検の実施
                <br />
                ・月次レポートの作成・提出
              </Fragment>,
          },
        ]}
      />
      <ServiceInfoSet
        num={ServicesListData[5].no}
        title={ServicesListData[5].title}
        titleSub={ServicesListData[5].titleSub}
        description={ServicesListData[5].text}
        imgPath="service-6.jpg"
        imgAlt="service-6"
        list={[
          {
            title: '業務設計/運用支援',
            text:
              <Fragment>
                ・現状業務を分析
                <br />
                ・分析結果に基づき業務設計
              </Fragment>,
          },
          {
            title: '開発サポート',
            text:
              <Fragment>
                お客様の開発業務に関する技術的なアドバイザリをCTOやTechLeadのような立場から行います。
                <br />
                ・Webサイトの企画・制作
                <br />
                ・自動化ツール設計・開発
                <br />
                ・開発環境の設計・構築
                <br />
                ・お客様の開発業務をサポート
              </Fragment>,
          },
          {
            title: 'セキュリティ',
            text:
              <Fragment>
                社内における情報セキュリティの策定・運用について支援します。
                <br />
                ・各種アセスメント
                <br />
                ・社内ガイドラインおよびルールの策定
                <br />
                ・セキュリティ教育
                <br />
                ・社内体制構築および運用支援
              </Fragment>,
          },
          {
            title: 'PC管理',
            text:
              <Fragment>
                ・業務運用上の疑問に対する回答
                <br />
                ・既存機器のリプレイス
                <br />
                ・クライアントPC導入及び設定
              </Fragment>,
          },
          {
            title: 'LAN構築管理',
            text:
              <Fragment>
                ・社内LAN設計・構築
                <br />
                ・既存環境分析及びチューニング
                <br />
                ・VPN環境構築
                <br />
                ・ルータ及びスイッチの導入・設定
              </Fragment>,
          },
          {
            title: 'ITツール導入・運用',
            text:
              <Fragment>
                ・ツールの選定・提案
                <br />
                ・導入支援・運用サポート
              </Fragment>,
          },
          {
            title: 'IT基盤構築設計・構築',
            text: 'ご要望にあわせたIT基盤の構築・設定が可能です。',
          },
          {
            title: '情報資産管理',
            text:
              <Fragment>
                ・業務情報(顧客情報/技術情報など)の管理を支援
                <br />
                ・サーバやクライアントPCなどのハードウェア管理を支援
                <br />
                ・ライセンス情報やサポート情報などの管理を支援
              </Fragment>,
          },
          {
            title: '情報共有',
            text:
              <Fragment>
                ・IT活用に関わるさまざまなノウハウを共有
                <br />
                ・IT関連情報を従業員の方々に随時解説
              </Fragment>,
          },
        ]}
      />
    </StaticTemplate>
  );
};
export default Service;
